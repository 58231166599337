const productData = {
    items: [
        {
            quantity: 100,
        },
        {
            quantity: 150,
        },
        {
            quantity: 200,
        },
        {
            quantity: 250,
        },
    ]
}
export default productData;