import React from 'react';
import ProductBanner from './productBanner/ProductBanner';
import ProductFeature from './productFeature/ProductFeature';
import ProductDetails from './productDetails/ProductDetails';
import Started from './getting-started/Started';

const Product = () => {
    return(
        <>
        <ProductBanner />
        {/* <ProductFeature /> */}
        {/* <ProductDetails /> */}
        {/* <Started /> */}
        </>
    )
}

export default Product;